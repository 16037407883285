/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware } from '#app'
import { toRaw } from 'vue'
import settingsPreviousRouteStore
  from '~/src/UserInterface/Engagement/store/SettingsPreviousRouteStore'

/**
 * This middleware must be called on the 2fa page
 *
 * Prerequisites for this middleware:
 * - None
 *
 * This middleware checks if the user authentication process has been initiated:
 * - If the process is not initiated, the user is redirected to the login page.
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  const meta = toRaw(from.meta)
  if (
    typeof meta.layout !== 'string'
    || ![
      'settings-profile',
      'workflow',
      'identity',
    ].includes(meta.layout)) {
    const store = settingsPreviousRouteStore()
    store.previousRoute = {
      name: meta.name!,
      params: from.params,
    }
  }
})
